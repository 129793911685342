import React, { PropsWithChildren, useEffect, useLayoutEffect, useRef, useState } from 'react';

import { restrictedMarkdownToHtml } from '../../utils/text';
import styles from './Summary.module.css';

type Props = PropsWithChildren<{
  isGenerating: boolean | undefined;
  isGenerated: boolean;
}>;

export function Summary({ children, isGenerating, isGenerated }: Props) {
  const summaryRef = useRef<HTMLDivElement>(null);
  const hintRef = useRef<HTMLDivElement>(null);
  const [height, setHeight] = useState(60);
  const [animation, setAnimation] = useState<'hide' | 'show' | undefined>();
  const animationStyle = !animation ? '' : styles[animation];

  // Use a layout effect to calculate height immediately before rendering
  useLayoutEffect(() => {
    /* Do nothing when the summary node isn't ready (we can't measure the
    height if the node is absent) */
    if (!summaryRef.current) {
      return;
    }

    /* During generation, we set the height to an arbitrary mid-range value, so
    that further animations do not look weird. */
    if (isGenerating) {
      setHeight(60);
      return;
    }

    /* Get bounding rectangle of summary node, and set height to the height of
    the node. */
    const { height } = summaryRef.current.getBoundingClientRect();
    const { height: hintHeight } = isGenerated
      ? (hintRef.current?.getBoundingClientRect() ?? { height: 0 })
      : { height: 0 };
    setHeight(height + hintHeight + 4);
  }, [children, isGenerated, isGenerating]);

  /* When the state changes from "generating" or to "generating", we handle
  different types of animations. */
  useEffect(() => {
    let timeoutId: ReturnType<typeof setTimeout> | undefined;

    if (isGenerating) {
      // Hide the summary when generating
      setAnimation('hide');
    } else if (isGenerating === false) {
      // Show when done generating
      setAnimation('show');
      // Reset animation after animation is done
      timeoutId = setTimeout(() => setAnimation(undefined), 1000);
    } else {
      /* We do not want to animate when rendering the first time, or when the
      component gets re-rendered for other reasons. */
      setAnimation(undefined);
    }

    return () => {
      // make sure to clear timeouts!
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
    };
  }, [isGenerating]);

  const containerHeight = isGenerating || animation !== undefined ? { height } : {};

  return (
    <div className={`${styles.summary} ${animationStyle}`} style={containerHeight}>
      <div
        ref={summaryRef}
        dangerouslySetInnerHTML={{ __html: restrictedMarkdownToHtml(children as string) }}
      />
      {isGenerated && (
        <span ref={hintRef} className={styles.summarizationHint}>
          Summarized by Ghostreader
        </span>
      )}
    </div>
  );
}
